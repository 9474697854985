<template>
  <div>
    <div class="markdown-editor">
      <textarea id="simple-mde" />
    </div>
    <div class="image-upload">
      <file-uploader
        :editor="editor"
        @uploaded="fileUploaded"
        @error="handleError"
      />
    </div>
  </div>
</template>

<script>
import SimpleMDE from 'simplemde';
import 'simplemde/dist/simplemde.min.css';
import FileUploader from './file-uploader';
import { mapState } from 'vuex';

export default {
  name: 'MarkdownEditor',
  components: {
    FileUploader,
  },
  props: {
    value: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  computed: {
    ...mapState(['activeProject']),
    editorValue() {
      if (!this.editor) return;
      return this.editor.value();
    },
  },
  watch: {
    'value': 'updateEditorValue',
    'editorValue': 'updateParentValue',
  },
  mounted() {
    this.editor = new SimpleMDE({
      element: this.$el.querySelector('#simple-mde'),
      spellChecker: false,
      status: false,
      toolbar: [
        'bold',
        'italic',
        '|',
        'heading-smaller',
        'heading-bigger',
        '|',
        'unordered-list',
        'ordered-list',
        '|',
        'link',
        '|',
        'preview',
        '|',
        'guide',
      ],
    });
    this.editor.value(this.value);
  },
  beforeDestroy() {
    this.editor.toTextArea();
    this.editor = null;
  },
  methods: {
    updateParentValue() {
      this.$emit('input', this.editor.value());
    },
    updateEditorValue(input) {
      if (input === this.editor.value()) return;
      this.editor.value(this.value);
    },
    fileUploaded(file) {
      // we can't use file.response.markdown as it doesn't contain the full path
      // web_url can also no longer be used.
      // see https://gitlab.hydra-newmedia.cloud/hydra/intern/feedback-tool-api/-/issues/30
      const imgUrl = new URL(file.response.full_path, 'https://gitlab.hydra-newmedia.cloud/');
      this.editor.value(
        `${this.editor.value()}\n![${file.response.alt}](${imgUrl})\n\n`,
      );
      // SimpleMDE.render does not render the preview, so double-toggle preview on file upload
      if (this.editor.isPreviewActive()) {
        this.editor.togglePreview();
        this.editor.togglePreview();
      }
      this.editor.codemirror.setCursor(this.editor.codemirror.lineCount(), 0);
    },
    handleError(file) {
      this.$notify({
        text: file.response.error,
        type: 'error',
        duration: 8000,
      });
    },
  },
};

</script>

<style lang="scss">
.markdown-editor {
  ul, ol {
    padding-left: 30px;
  }

  ul {
    list-style: initial;
    margin: 15px 0;

    li {
      margin: 0px;
    }
  }

  ol {
    list-style-type: decimal;
  }

  h1 {
    border-bottom: none;
    font-weight: 500;
  }

  .editor-preview > :first-child {
    margin-top: 0px;
  }

  .editor-preview > :last-child {
    margin-bottom: 0px;
  }

  .CodeMirror {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .editor-toolbar {
    background-color: white;
  }
}
</style>

<style scoped lang="scss">
.image-upload {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  min-height: 50px;
  background-color: white;
}
</style>

<template>
  <div class="page issue-details">
    <div class="quick-nav prev-issue">
      <div class="button-wrap">
        <md-button
          :disabled="!prevIssue"
          class="md-icon-button"
          @click="goToIssueRelative(-1)"
        >
          <md-icon>keyboard_arrow_left</md-icon>
        </md-button>
      </div>
    </div>
    <div class="page-margin main-issue-area">
      <div
        v-if="issue"
        class="content"
      >
        <div class="control">
          <div class="meta">
            <span
              class="issue-state"
              :class="issue.state"
            >{{ issue.state }}</span>
            <span>
              Issue #{{ $route.params.issueId }} - Opened {{ getTimeFrom(issue.created_at) }} by
            </span>
            <el-avatar :user="issue.author" />
          </div>
          <md-button
            v-if="!edit && issue.userEditable"
            class="md-raised edit-button"
            @click="edit = true"
          >
            <md-icon>edit</md-icon>
            <span>Edit</span>
          </md-button>
        </div>
        <edit-issue
          :issue="issue"
          :edit="edit"
          @submit="saveIssue"
          @issue-changed="changedIssueData => issue = changedIssueData"
        >
          <div
            v-if="edit"
            class="buttons"
          >
            <md-button
              class="md-raised cancel-button"
              :disabled="saving"
              @click="resetIssue"
            >
              <span>Cancel</span>
            </md-button>
            <spinner-button
              spin-replace
              class="save-button md-raised md-primary"
              type="submit"
              :spin="saving"
              :disabled="saving"
            >
              <span>Save</span>
            </spinner-button>
          </div>
        </edit-issue>
      </div>
      <div
        v-else
        class="spinner-container"
      >
        <div>
          <spinner />
        </div>
      </div>
      <div class="notesWrap">
        <transition name="fade">
          <div
            v-if="notesFailed"
            class="notes notes-failed"
          >
            <div class="">
              <i class="material-icons">warning</i>
            </div>
            <div>
              Comments could not be loaded
            </div>
          </div>
          <div
            v-if="notes !== null && notes.length && issue"
            class="notes"
          >
            <div class="notes-nav">
              <h3>Activity</h3>
              <md-button
                v-if="notes.length > 1"
                @click="scrollTo($el.querySelector('.manage-issue'))"
              >
                <md-icon>arrow_downward</md-icon> Jump to bottom
              </md-button>
            </div>
            <fbt-note
              v-for="note in notes"
              :key="note.id"
              :note="note"
              @delete="inquireDelete"
              @note-changed="changedNote => note = changedNote"
            />
          </div>
        </transition>
      </div>
      <div
        v-if="issue"
        class="manage-issue"
      >
        <div class="label">
          New comment
        </div>
        <markdown-editor
          v-model="newNoteBody"
          class="comments-editor"
        />
        <div class="manage-buttons">
          <spinner-button
            v-if="issue.state === 'done' || issue.state === 'approved'"
            spin-replace
            class="md-warn reopen-button"
            :disabled="newNoteBody === '' || reopening"
            :spin="reopening"
            @click="reopen"
          >
            <span>Comment &amp; reopen</span>
          </spinner-button>
          <spinner-button
            v-if="issue.state !== 'done'"
            class="md-raised md-primary comment-button"
            :disabled="newNoteBody === '' || commenting"
            :spin="commenting"
            @click="comment"
          >
            <md-icon slot="icon">
              comment
            </md-icon>
            <span>Comment</span>
          </spinner-button>
          <spinner-button
            v-if="issue.state === 'done'"
            class="md-raised md-primary approve-button"
            :disabled="approving"
            :spin="approving"
            @click="approve"
          >
            <md-icon slot="icon">
              done
            </md-icon>
            <span><span v-if="newNoteBody.length > 0">Comment &amp; </span>Approve</span>
          </spinner-button>
        </div>
      </div>
    </div>
    <div class="quick-nav next-issue">
      <div class="button-wrap">
        <md-button
          :disabled="!nextIssue"
          class="md-icon-button"
          @click="goToIssueRelative(1)"
        >
          <md-icon>keyboard_arrow_right</md-icon>
        </md-button>
      </div>
    </div>
    <md-dialog :md-active.sync="deleteDialog">
      <md-dialog-title>Delete Comment</md-dialog-title>
      <md-dialog-content>Do you want to delete the comment?</md-dialog-content>
      <md-dialog-actions>
        <md-button
          :disabled="deleting"
          class="md-primary"
          @click="abortDelete"
        >
          Abort
        </md-button>
        <spinner-button
          spin-replace
          class="md-primary"
          :disabled="deleting"
          :spin="deleting"
          @click="deleteNote"
        >
          <span>Delete</span>
        </spinner-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import EditIssue from '../components/edit-issue';
import ElAvatar from '../components/el-avatar';
import FbtNote from '../components/fbt-note';
import { mapState } from 'vuex';
import { replaceUploadPaths } from '../components/utils';
import MarkdownEditor from '../components/markdown-editor';
import SpinnerButton from '../components/spinner-button';
import Spinner from '../components/spinner';

export default {
  name: 'IssueDetails',
  components: {
    EditIssue,
    ElAvatar,
    FbtNote,
    MarkdownEditor,
    SpinnerButton,
    Spinner,
  },
  data() {
    return {
      issueOrig: null,
      issue: null,
      edit: false,
      notes: null,
      notesFailed: false,
      newNoteBody: '',
      uploadingImage: false,
      commenting: false,
      reopening: false,
      approving: false,
      deleting: false,
      saving: false,
      noteToDelete: null,
      deleteDialog: false,
    };
  },
  computed: {
    ...mapState(['activeProject', 'issueContext']),
    nextIssue() {
      const issues = this.issueContext;
      if (!this.issue) return null;
      const i = issues.findIndex(issue => issue.iid === this.issue.iid);
      return issues[i + 1];
    },
    prevIssue() {
      const issues = this.issueContext;
      if (!this.issue) return null;
      const i = issues.findIndex(issue => issue.iid === this.issue.iid);
      return issues[i - 1];
    },
  },
  watch: {
    '$route.params': 'init',
  },
  mounted() {
    this.init();
  },
  methods: {
    goToIssueRelative(n = 1) {
      const issues = this.$store.state.issueContext;
      const i = issues.findIndex(issue => issue.iid === this.issue.iid);
      const issue = issues[i + n];
      if (!issue) return;
      this.$router.replace({
        name: 'issue.details',
        params: {
          deliverableId: this.$route.params.deliverableId,
          issueId:  issue.iid,
        },
      });
    },
    scrollTo(element) {
      element.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    },
    inquireDelete(note) {
      this.deleteDialog = true;
      this.noteToDelete = note;
    },
    abortDelete() {
      this.deleting = false;
      this.deleteDialog = false;
    },
    deleteNote() {
      this.deleting = true;
      const { projectId, issueId } = this.$route.params;
      const url = `/projects/${projectId}/issues/${issueId}/notes/${this.noteToDelete.id}`;
      this.$http.delete(url).then(response => {
        this.notes.splice(this.notes.findIndex(
          note => note.id === response.data.id,
        ), 1);
      }).catch(error => {
        this.$notify({
          title: 'Failed to delete comment!',
          text: error.response && error.response.data.message || 'Unknown Error',
          type: 'error',
          duration: 8000,
        });
      }).then(() => {
        this.deleting = false;
        this.deleteDialog = false;
      });
    },
    async comment() {
      this.commenting = true;
      const comment = await this.postComment();
      this.commenting = false;
      this.notes.push(comment);
      this.newNoteBody = '';
    },
    postComment() {
      const { projectId, issueId } = this.$route.params;
      return this.$http.post(
        `/projects/${projectId}/issues/${issueId}/notes`, {
          body: this.newNoteBody,
        },
      ).then(response => {
        const imgUrl =
          new URL(`-/project/${projectId}`, 'https://gitlab.hydra-newmedia.cloud/').toString();
        response.data.body = replaceUploadPaths(response.data.body, imgUrl);
        return response.data;
      }).catch(error => {
        this.$notify({
          title: 'Failed to create comment!',
          text: error.response && error.response.data.message || 'Unknown Error',
          type: 'error',
          duration: 8000,
        });
      });
    },
    changeState(state) {
      const { projectId, issueId } = this.$route.params;
      return this.$http.post(`/projects/${projectId}/issues/${issueId}/_${state}`)
        .then(response => this.issue.state = response.data.state)
        .catch(error => {
          this.$notify({
            title: 'Error while changing the issue state!',
            text: error.response && error.response.data.message || 'Unknown Error',
            type: 'error',
            duration: 8000,
          });
        })
        .then(this.fetchNotes);
    },
    async reopen() {
      this.reopening = true;
      await this.postComment();
      await this.changeState('reopen');
      this.reopening = false;
      this.newNoteBody = '';
    },
    async approve() {
      this.approving = true;
      if (this.newNoteBody.length > 0) await this.postComment();
      await this.changeState('approve');
      this.approving = false;
      this.newNoteBody = '';
    },
    saveIssue() {
      this.saving = true;
      const url = `/projects/${this.activeProject.id}/issues/${this.$route.params.issueId}`;
      this.$http.put(url, this.issue).then(response => {
        this.issue = response.data;
        this.issueOrig = this.clone(this.issue);
        this.edit = false;
      }).catch(error => {
        this.$notify({
          title: 'Failed to update issue!',
          text: error.response && error.response.data.message || 'Unknown Error',
          type: 'error',
          duration: 8000,
        });
      }).then(() => this.saving = false);
    },
    resetIssue() {
      this.edit = false;
      this.issue = this.clone(this.issueOrig);
    },
    clone(element) {
      return Object.assign({}, element);
    },
    getTimeFrom(start) {
      return moment(start).fromNow();
    },
    fetchIssue() {
      const { projectId, issueId } = this.$route.params;
      const imgUrl =
        new URL(`-/project/${projectId}`, 'https://gitlab.hydra-newmedia.cloud/').toString();
      return this.$http.get(`/projects/${projectId}/issues/${issueId}`)
        .then(response => {
          this.issue = response.data;
          this.issue.description = replaceUploadPaths(
            this.issue.description, imgUrl,
          );
          this.issueOrig = this.clone(this.issue);
        });
    },
    async fetchNotes() {
      const { projectId, issueId } = this.$route.params;
      this.notes = await this.$http.get(`/projects/${projectId}/issues/${issueId}/notes`)
        .then(({ data }) => {
          this.notesFailed = false;
          const imgUrl =
            new URL(`-/project/${projectId}`, 'https://gitlab.hydra-newmedia.cloud/').toString();
          data.forEach(n => n.body = replaceUploadPaths(n.body, imgUrl));
          return data.sort((a, b) => {
            return (a.created_at > b.created_at ? 1 : -1);
          });
        }).catch(e => {
          this.notesFailed = true;
          throw e; // for sentry
        });
    },
    init() {
      // this.issue = null;
      // this.notes = null;
      this.fetchIssue();
      this.fetchNotes();
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~colors';
.issue-details {
  flex-direction: row;
  align-items: stretch;

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;

    button.md-button {
      display: flex;
      box-shadow: 0 0 transparent;
      margin: 0 0 0 15px;
    }
  }
  .title-input {
    input {
      line-height: 20px;
      font-weight: bold;
      font-size: 2em;
      border-bottom: 1px solid #ccc;
      padding-bottom: 12px;
      height: 50px;
      border: none;
    }

    &::after {
      bottom: 7px;
    }
  }
}

#app .issue-details .cancel-button {
  background-color: #e6e6e6;

  &:hover {
    background-color: rgba(120, 120, 120, 0.3);
  }
}

.quick-nav {
  .button-wrap {
    margin-top: 50%;
    position: sticky;
    top: 40%;
    overflow: hidden;
  }
}

.main-issue-area {
  flex: 1;
  min-width: 300px;
}

.control {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2em;

  span {
    display: flex;
    align-items: center;
  }

  .issue-state {
    margin-right: 8px;
    margin-left: 0;
  }

  .meta {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.55);
    justify-content: space-between;
    padding-left: 2px;

    .md-avatar {
      margin-right: 5px;
    }
  }

  img {
    border-radius: 100%;
    width: 24px;
    height: 24px;
    margin: 0 4px 0 10px;
  }

  button.edit-button {
    margin: 0px;
    .md-button-content {
      display: flex;
    }

    .md-icon-font {
      color: black;
    }

    .md-icon {
      font-size: 20px !important;
      margin-right: 5px;
    }
  }
}

.notes-spinner-container {
  text-align: center;
}

.notesWrap {
  position: relative;
}

.notes-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notes {
  border-radius: 4px;
  width: 100%;
  margin: 20px 0;

  h3 {
    padding-left: 20px;
    font-size: 2em;
    font-weight: 200;
  }
}

.notes-failed {
  text-align: center;
  padding: 1em;
  font-size: 2em;

  i.material-icons {
    font-size: 2em;
    margin-bottom: 25px;
    color: $warn;
  }
}

.notes-spinner {
  display: block;
  margin: 30px auto;
}

.label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.manage-issue {
  margin-top: 10px;
}

.manage-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0;

  button:last-of-type {
    margin-right: 0;
  }
}
#app .comment-button, .save-button, .approve-button {
  .md-button-content, .md-icon{
    color: #fff !important;
    .md-icon {
      font-size: 20px !important;
    }
  }
}
.approve-button, .comment-button, .reopen-button {
  .md-button-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      margin-right: 5px;
    }
  }
}
</style>

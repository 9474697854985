<template>
  <div class="page">
    <div
      v-if="issues && !loading"
      class="content"
    >
      <div class="tabs-container">
        <div
          class="move-left move"
          @mousedown="scrollLeft"
          @mouseup="cancelScroll"
        >
          <md-icon>chevron_left</md-icon>
        </div>
        <div
          ref="tabs"
          class="tabs"
        >
          <router-link
            v-for="deliverable in activeProject.deliverables"
            :key="deliverable.iid"
            :to="{
              name: 'deliverable',
              params: {
                projectId: $route.params.projectId,
                deliverableId: deliverable.iid,
              },
              query: $route.query,
            }"
            class="tab"
            :class="{ 'active': deliverable.iid === parseInt($route.params.deliverableId, 10) }"
            @click="goToDeliverable(deliverable)"
          >
            <md-button>
              <span>{{ deliverable.title }}</span>
              <span class="deliverable-count">{{ getDelivIssueCount(deliverable.iid) }}</span>
            </md-button>
          </router-link>
        </div>
        <div
          class="move-right move"
          @mousedown="scrollRight"
          @mouseup="cancelScroll"
        >
          <md-icon>chevron_right</md-icon>
        </div>
      </div>
      <div class="control-deliverable">
        <div class="filter">
          <md-field class="search">
            <md-icon>search</md-icon>
            <label>Filter issues</label>
            <md-input
              v-model="search"
              @change="queryChange"
            />
          </md-field>
          <md-field class="order-filter">
            <label for="order">Order by</label>
            <md-select
              id="order"
              v-model="orderFilter"
              name="order"
              @change="queryChange"
            >
              <md-option value="created_ASC">
                Newest created
              </md-option>
              <md-option value="created_DESC">
                Oldest created
              </md-option>
              <md-option value="updated_ASC">
                Newest updated
              </md-option>
              <md-option value="updated_DESC">
                Oldest updated
              </md-option>
              <md-option value="title_ASC">
                Alphabetical A-Z
              </md-option>
              <md-option value="title_DESC">
                Alphabetical Z-A
              </md-option>
            </md-select>
          </md-field>
          <md-field class="label-filter">
            <label for="labels">Labels</label>
            <md-select
              id="labels"
              v-model="labelFilter"
              name="labels"
              multiple
              @change="queryChange"
            >
              <md-option
                v-for="{ name } in allLabels"
                :key="name"
                :value="name"
              >
                {{ name }}
              </md-option>
            </md-select>
          </md-field>
          <md-field class="show-filter">
            <label for="show=">Show</label>
            <md-select
              id="show="
              v-model="showFilter"
              name="show="
              multiple
              @change="queryChange"
            >
              <md-option value="open">
                Open
              </md-option>
              <md-option value="done">
                Done
              </md-option>
              <md-option value="approved">
                Approved
              </md-option>
            </md-select>
          </md-field>
        </div>
        <md-button
          class="md-primary add-issue"
          @click="goToCreate()"
        >
          New Issue
        </md-button>
      </div>
      <div class="issues">
        <router-link
          v-for="issue in activeDeliverable"
          :key="issue.iid"
          :to="{
            name: 'issue.details',
            params: {
              projectId: $route.params.projectId,
              deliverableId: $route.params.deliverableId,
              issueId: issue.iid,
            },
            query: $route.query,
          }"
          class="issue"
        >
          <div class="main-information">
            <div class="title-container">
              <span class="title">{{ issue.title }}</span>
              <span
                v-if="issue.meta && issue.meta.context"
                class="meta-context"
              >
                ({{ issue.meta.context }})
              </span>
              <span
                class="issue-state"
                :class="issue.state"
              >{{ issue.state }}</span>
              <ul class="labels">
                <li
                  v-for="label in issue.labels"
                  :key="label.name"
                  class="label issue-state"
                  :style="{ 'background-color': label.color }"
                >
                  {{ label.name }}
                </li>
              </ul>
            </div>
            <div class="further-infos">
              <span>#{{ issue.iid }}</span>
              <span>Opened {{ getTimeFrom(issue.created_at) }}</span>
              <span>by {{ issue.author.name }}</span>
            </div>
          </div>
          <div class="detail-information">
            <span><md-icon>comment</md-icon> {{ issue.user_notes_count }}</span>
            <span>updated {{ getTimeFrom(issue.updated_at) }}</span>
          </div>
        </router-link>
      </div>
    </div>
    <div
      v-if="loading"
      class="spinner-container"
    >
      <div>
        <spinner />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import flatten from 'lodash/flatten';
import intersection from 'lodash/intersection';
import debounce from 'lodash/debounce';
import Spinner from '../components/spinner';

export default {
  name: 'IssueList',
  components: {
    Spinner,
  },
  data() {
    return {
      noQuery: false,
      issues: null,
      loading: false,
      scrollInterval: null,
      search: '',
      orderFilter: 'created_ASC',
      labelFilter: [],
      showFilter: ['done', 'open'],
    };
  },
  computed: {
    allLabels() {
      const l = uniqBy(flatten(this.issues.map(i => i.labels.map(l => l))), 'name');
      l.push({ name: 'unlabeled' });
      return l;
    },
    ...mapState(['activeProject']),
    activeDeliverable() {
      const rules = {
        'title_ASC': { property: 'title', order: 1 },
        'title_DESC': { property: 'title', order: -1 },
        'updated_ASC': { property: 'updated_at', order: 1, type: 'date' },
        'updated_DESC': { property: 'updated_at', order: -1, type: 'date' },
        'created_ASC': { property: 'created_at', order: 1, type: 'date' },
        'created_DESC': { property: 'created_at', order: -1, type: 'date' },
      };
      const filter = rules[this.orderFilter];

      if (!this.issues) return [];

      const issues = this.issues
        //filter by deliverable
        .filter(issue => {
          return issue.deliverableIds
            .includes(parseInt(this.$route.params.deliverableId, 10));
        })
        // filter by search
        .filter(issue => {
          const matchesIssueId = this.search.length && `#${issue.iid}`.includes(this.search);
          const regExp = new RegExp(this.search, 'gi');
          return matchesIssueId || regExp.test(issue.title) || regExp.test(issue.meta.context);
        })
        // filter by state
        .filter(issue => this.showFilter.includes(issue.state))
        // filter by label
        .filter(issue => {
          const labels = issue.labels.map(l => l.name);
          if (!labels.length) return this.labelFilter.includes('unlabeled');
          return intersection(this.labelFilter, labels).length;
        })
        // order
        .sort((a, b) => {
          const aProperty = get(a, filter.property);
          const bProperty = get(b, filter.property);

          const comparison = filter.type === 'date'
            ? (aProperty < bProperty || bProperty === undefined ? 1 : -1)
            : aProperty.localeCompare(bProperty, 'de', { sensitivity: 'base' });

          if (aProperty === bProperty) return 0;
          return comparison * filter.order;
        });

      // dirty fix to not crash for issues without author info
      issues.forEach(i => {
        if (!i.author) {
          i.author = {
            name: 'Unknown',
            email: 'unknown@example.com',
          };
        }
      });
      return issues;
    },
  },
  watch: {
    '$route.params': 'applyQueryParams',
    'activeDeliverable'() {
      if (this.activeDeliverable) this.$store.commit('setIssueContext', this.activeDeliverable);
    },
  },
  async mounted() {
    if (!Object.keys(this.$route.query).length) this.noQuery = true;
    this.applyQueryParams();
    await this.fetchIssues();
    if (this.noQuery) this.labelFilter = this.allLabels.map(l => l.name);
  },
  methods: {
    // we use a debounce here, because the function is invoked 4 times in a row at startup...
    // this leads to weird redirect "ghost bugs".
    queryChange: debounce(function() {
      this.$nextTick(() => {
        this.$router.replace({
          query: {
            q: this.search,
            label: this.labelFilter,
            show: this.showFilter.join(),
            order: this.orderFilter,
          },
        }).catch(err => {
          if (err.name !== 'NavigationDuplicated') throw err;
        });
      });
    }, 100),
    applyQueryParams() {
      const { q, order, label, show } = this.$route.query;
      this.$nextTick(() => {
        // set filter options from route if available
        if (q) this.search = q;
        if (order) this.orderFilter = order;
        if (label) this.labelFilter = label || this.labelFilter;
        if (show !== undefined) this.showFilter = show === '' ? [] : show.split(',');
      });
    },
    getDelivIssueCount(iid) {
      return this.issues.reduce((count, issue) => {
        if (issue.deliverableIds.includes(iid)) count++;
        return count;
      }, 0);
    },
    goToCreate() {
      this.$router.push({
        name: 'issue.create',
        params: {
          projectId: this.$route.params.projectId,
          deliverableId: this.$route.params.deliverableId,
        },
      });
    },
    getTimeFrom(start) {
      return moment(start).fromNow();
    },
    cancelScroll() {
      clearInterval(this.scrollInterval);
    },
    scrollRight() {
      this.scrollInterval = setInterval(() => {
        this.$el.querySelector('.tabs').scrollLeft += 12;
      }, 25);
    },
    scrollLeft() {
      this.scrollInterval = setInterval(() => {
        this.$el.querySelector('.tabs').scrollLeft -= 12;
      }, 25);
    },
    fetchIssues() {
      this.loading = true;
      return this.$http.get(`/projects/${this.$route.params.projectId}/issues`)
        .then(response => this.issues = response.data)
        .catch(() => this.$router.replace({ name: 'notFound' }))
        .finally(() => this.loading = false);
    },
  },
};
</script>

<style lang="scss">
.tabs-container.md-ripple {
  background-color: #fff;
}
.md-button-content {
  display: flex;
  align-items: center;
}
</style>

<style lang="scss" scoped>
@import '~colors';

.content{
  background-color: #fafafa;
}

.tabs-container {
  display:flex;
  min-height: 48px;
  flex: 1;
  box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  background-color: white;
  contain: content;
  padding: 0 1.5em;
  z-index: 5;

  .move {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, .54);
    cursor: pointer;
  }
}

.tabs {
  display: flex;
  flex: 1;
  text-transform: uppercase;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;

  div {
    margin-top: 0px;
  }
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2px;
  margin-right: 2px;

  button {
    border-radius: 0px;
    margin: 0px;
    color: rgba(0, 0, 0, .54);
    display: flex;
    align-items: center;
    min-height: 48px;
  }

  .deliverable-count {
    height: 24px;
    width: 24px;
    font-size: 11px;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
  }

  &.active {
    border-bottom: 2px solid $primary-color;

    button {
      color: rgba(0, 0, 0, .87);
    }
  }
}

.control-deliverable {
  align-items: center;
  display: flex;
  flex: 1;
  border-bottom: 1px dotted #E6E6E6;
  padding: 0 2em;
  height: 90px;
  background-color: white;

  .filter {
    flex: 1;
    display: flex;
    align-items: center;
    margin-top: -16px;

    .search {
      flex: 1;
      margin: 0px;

      .md-icon {
        position: static;
      }

      .md-icon::after {
        background: transparent;
      }
    }

    .order-filter, .show-filter, .label-filter {
      margin: 0px;
      margin-left: 20px;
      width: 190px;
    }

    .show-filter {
      margin: 0px 20px;
    }
  }

  .add-issue {
    margin: 0px;
  }
}

.content .issues .issue {
  padding: 20px 2em;
  flex: 1 1 auto;
  align-items: center;
  position: relative;
  border-top: 1px solid #E6E6E6;
  margin-top: 0px;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  contain: content;
  color: rgba(0, 0, 0, .87);

  .main-information {
    flex: 1 1 auto;
  }

  .detail-information {
    flex: 0 0 auto;
    margin-left: 20px;
  }

  .title-container {
    display: flex;
    align-items: center;
  }

  .title {
    font-weight: bold;
  }

  .labels {
    display: flex;
    margin-left: 20px;

    li {
      margin: 0px;
      margin-right: 3px;
    }
  }

  .meta-context {
    margin-left: 6px;
  }

  .further-infos {
    color: rgba(0,0,0,0.55);
    margin-top: 4px;
    display: flex;

    span {
      margin-right: 6px;
    }
  }

  .detail-information {
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    display: flex;
    color: rgba(0,0,0,0.55);

    i {
      width: 20px;
      font-size: 18px !important;
      margin-right: -4px;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.10);
  }
}

.issue:first-child {
  border-top: 1px solid transparent;
}

.content{
  margin-top: 2em;
  margin-bottom: 1em;
}

@media (min-width: 1220px) {
  .content{
    box-shadow: 0 1px 3px #b5b5b5;
    border-radius: 3px;
  }

  .tabs-container{
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
}

</style>

<template>
  <div>
    <no-deliverables
      v-if="activeProject && !activeProject.deliverables.length"
      style="margin-top: 50px;"
    />
    <router-view v-if="!loading && activeProject && activeProject.deliverables.length" />
    <div
      v-if="loading"
      class="spinner-container"
    >
      <spinner />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NoDeliverables from '../components/no-deliverables';
import Spinner from '../components/spinner';

export default {
  name: 'ProjectDetails',
  components: {
    NoDeliverables,
    Spinner,
  },
  data() {
    return {
      project: null,
      loading: false,
    };
  },
  computed: {
    ...mapState(['activeProject']),
  },
  watch: {
    '$route.params.projectId': 'maybeProjectFetch',
    '$route.params.deliverableId': 'checkDeliverable',
  },
  mounted() {
    this.fetchProject();
  },
  destroyed() {
    this.$store.commit('setActiveProject', null);
  },
  methods: {
    checkDeliverable() {
      if (!this.project.deliverables.length) return;
      // route to first deliverable
      if (this.$route.name === 'project.details' && !this.$route.params.deliverableId) {
        this.$router.replace({
          name: 'deliverable',
          params: {
            deliverableId: this.project.deliverables[0].iid,
          },
          query: this.$route.query,
        });
      }
    },
    deliverableExists() {
      return this.project.deliverables.some(
        deliverable => deliverable.iid === parseInt(this.$route.params.deliverableId, 10),
      );
    },
    fetchProject() {
      this.loading = true;
      this.$http.get(`/projects/${this.$route.params.projectId}`)
        .then(response => {
          this.$store.commit('setActiveProject', response.data);
          this.project = response.data;
          this.checkDeliverable();
        })
        .catch(() => {
          this.$store.commit('setActiveProject', null);
          this.$router.replace({ name: 'notFound' });
        })
        .then(() => this.loading = false);
    },
    maybeProjectFetch() {
      // do not fetch (and remount everything) if nothing changed
      if (this.$route.params.projectId === this.project.id.toString());
      else return this.fetchProject();
    },
  },
};
</script>

<template>
  <div
    v-if="issue"
    class="edit-issues"
    :class="{ edit }"
  >
    <form @submit.prevent="$emit('submit')">
      <h1 v-show="!edit">
        {{ issue.title }}
      </h1>
      <md-field
        v-show="edit"
        class="title"
      >
        <label>Section ID & Title</label>
        <md-input
          v-model="issueData.title"
          required
        />
      </md-field>
      <div
        v-if="!edit"
        class="description-output"
      >
        <md-renderer
          :text="issue.description"
        />
      </div>
      <div class="description-input">
        <label v-if="edit">Description</label>
        <markdown-editor
          v-if="edit"
          v-model="issueData.description"
        />
      </div>
      <div><slot /></div>
    </form>
  </div>
</template>

<script>
import MarkdownEditor from './markdown-editor';
import MdRenderer from './md-renderer';

export default {
  name: 'IssueDetails',
  components: {
    MarkdownEditor,
    MdRenderer,
  },
  props: {
    edit: {
      type: Boolean,
      required: true,
    },
    issue: {
      type: Object,
      required: true,
    },
  },
  computed: {
    issueData: {
      get() {
        return this.issue;
      },
      set(changedIssueData) {
        this.$emit('issue-changed', changedIssueData);
      },
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
@import '~colors';

#app .edit-issues .description-output {
  ul, ol {
    padding-left: 30px;
  }

  ul {
    list-style: initial;
    margin: 15px 0;

    li {
      margin: 0px;
    }
  }

  ol {
    list-style-type: decimal;
  }

  h1 {
    border-bottom: none;
    font-weight: 500;
  }
}

#app .edit-issues .description-output > div > :first-child {
  margin-top: 0px;
}

#app .edit-issues .description-output > div > :last-child {
  margin-bottom: 0px;
}
#app .title label {
  font-size: 1.6em;
  top: 32px;
}
#app .title input {
  font-weight: bold;
  line-height: 20px;
  font-size: 2em;
  height: 50px;
}

#app .title.md-focused label,
#app .title.md-has-value label {
  font-size: 12px;
  top: -4px;
}

</style>

<style scoped lang="scss">
@import '~colors';

.edit-issues {
  box-shadow: 0 2px 3px #ababab;
  background-color: #fafafa;
  border-radius: 3px;

  &.edit{
    padding: 20px;
  }
}

h1 {
  margin-bottom: 20px;
  padding: 20px;
  font-weight: 500;
  margin: 0;
}

label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.description-output, .CodeMirror {
  padding: 20px;
  margin: 20px 0;
}

.description-output {
  margin: 0;
  padding: 25px 20px;
}

.hidden {
  height: 0px;
  visibility: hidden;
  margin: 0px;
  padding: 0px;
  overflow:hidden;
}
.md-primary {
  color: white;
}

</style>

import md from 'markdown-it';
import taskLists from 'markdown-it-task-lists';
import emoji from 'markdown-it-emoji';
import html5embed from 'markdown-it-html5-embed';

const parser = md({
  linkify: true,
})
  .use(html5embed, {
    html5embed: {
      useImageSyntax: true, // Enables video/audio embed with ![]() syntax (default)
      useLinkSyntax: true, // Enables video/audio embed with []() syntax
    },
  })
  .use(emoji)
  .use(taskLists);

export default {
  name: 'md-renderer',
  props: ['text'],
  render(createElement) {
    return createElement('div', {
      domProps: {
        innerHTML: parser.render(this.text),
        className: 'md-renderer',
      },
    });
  },
};

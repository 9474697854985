<template>
  <div class="projects-select">
    <ul>
      <li
        v-for="project in projects"
        :key="project.id"
      >
        <label>
          <input
            v-model="userData.projects"
            type="checkbox"
            :value="project.id"
            class="md-primary"
          >
          <fbt-project-small :project="project" />
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
import FbtProjectSmall from './fbt-project-small';

export default {
  name: 'ProjectsSelect',
  components: {
    FbtProjectSmall,
  },
  props: {
    projects: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userData: {
      get() {
        return this.user;
      },
      set(changedUserData) {
        this.$emit('user-changed', changedUserData);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import '~colors';

ul {
  margin-top: 10px;
  overflow: auto;
}

li label{
  display: flex;
  align-items: center;
  margin: 5px 0px 5px 0px;
}

input {
  margin-right: 15px;
}
</style>

<template>
  <div
    class="fbt-note"
    :class="[transitionStatus[note.body], {'system-note': note.system}]"
  >
    <div v-if="!note.system">
      <div class="menu">
        <div class="meta">
          <el-avatar :user="note.author" />
          <span class="soft">&nbsp;commented&nbsp;</span>
          <span class="soft">
            <el-date :date="note.created_at" />
          </span>
        </div>
        <div class="actions">
          <i
            v-if="note.userEditable && !edit"
            class="material-icons edit"
            @click="edit = true"
          >
            <span>edit</span>
          </i>
          <i
            v-if="note.userEditable && !edit"
            class="material-icons delete"
            @click="$emit('delete', note)"
          >
            <span>delete</span>
          </i>
        </div>
      </div>
      <div class="body">
        <md-renderer
          v-if="!edit"
          :text="note.body"
        />
        <markdown-editor
          v-if="edit"
          v-model="noteData.body"
          class="comments-editor"
        />
        <div
          v-if="edit"
          class="buttons"
        >
          <md-button
            class="md-raised cancel-button"
            :disabled="saving"
            @click="cancel"
          >
            <span>Cancel</span>
          </md-button>
          <spinner-button
            spin-replace
            class="save-button md-raised md-primary"
            type="submit"
            :spin="saving"
            :disabled="saving"
            @click="save"
          >
            <span>Save</span>
          </spinner-button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="soft"
    >
      <span class="state-text">Issue state changed to</span>
      <span
        class="issue-state"
        :class="transitionStatus[note.body]"
      >
        {{ transitionStatus[note.body] }}
      </span>
      <el-date :date="note.created_at" />
    </div>
  </div>
</template>

<script>
import ElAvatar from './el-avatar';
import MdRenderer from './md-renderer';
import ElDate from './el-date';
import MarkdownEditor from './markdown-editor';
import SpinnerButton from './spinner-button';

export default {
  name: 'FbtNote',
  components: {
    ElAvatar,
    MdRenderer,
    ElDate,
    MarkdownEditor,
    SpinnerButton,
  },
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      edit: false,
      saving: false,
      bodyOrig: this.note.body,
      transitionStatus: {
        reopened: 'open',
        closed: 'done',
        approved: 'approved',
      },
    };
  },
  computed: {
    noteData: {
      get() {
        return this.note;
      },
      set(changedNote) {
        this.$emit('note-changed', changedNote);
      },
    },
  },
  methods: {
    cancel() {
      this.noteData.body = this.bodyOrig;
      this.edit = false;
    },
    save() {
      this.saving = true;
      const { projectId, issueId } = this.$route.params;
      const url = `/projects/${projectId}/issues/${issueId}/notes/${this.note.id}`;
      this.$http.put(url, { body: this.note.body }).then(response => {
        this.bodyOrig = response.data.body;
        this.edit = false;
      }).catch(error => {
        this.$notify({
          title: 'Failed to update comment!',
          text: error.response && error.response.data.message || 'Unknown Error',
          type: 'error',
          duration: 8000,
        });
      }).then(() => this.saving = false);
    },
  },
};
</script>

<style lang="scss" scoped>
.fbt-note{
  border-bottom: 1px solid rgb(231, 231, 231);
  padding: 0;
  background-color: #fafafa;
  border-radius: 4px;
  margin-bottom: 10px;
}

.fbt-note:last-child{
  border-bottom: 1px solid transparent;
}

.el-avatar{
  padding-left: 0;
  font-weight: 500;
  font-size: 1.1em;
  padding-right: 2px;
}

.menu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.meta {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.body{
  padding: 0 20px;
  padding-bottom: 10px;
}

#app .buttons {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .md-button {
    box-shadow: none;
  }
  .save-button span {
    color: #fff;
  }
  .cancel-button {
    background-color: #e6e6e6;
  }
}
.soft {
  color: rgb(93, 93, 93);
}

.actions {
  display: flex;
  align-items: center;

  i {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.9);
    margin: 4px;
  }

  .edit {
    font-size: 20px;
  }
}

.system-note {
  > div {
    display: flex;
    align-items: center;
    padding: 15px 20px;
  }

  &.done {
    background-color: rgba(255, 152, 0, 0.3);
  }

  &.approved {
    background-color: rgba(76, 175, 80, 0.3);
  }

  &.open {
    background-color: rgba(33, 150, 243, 0.3);
  }
}

.issue-state {
  margin-right: 5px;
}

.state-text {
  font-weight: 500;
}

</style>

<template>
  <div class="page page-margin admin-user-list">
    <div class="warning">
      This user list is <strong>NOT</strong> used anymore and does <strong>NOT</strong> apply. It is kept for reference only.
      It might be removed in the future.

      <br /><br />
      You can edit users in hy projects:
      <a href="https://projects.hydra-newmedia.cloud/admin/users>">https://projects.hydra-newmedia.cloud/admin/users</a>
    </div>
    <div class="filter">
      <md-field class="search">
        <md-icon>search</md-icon>
        <label>Filter users</label>
        <md-input v-model="search" />
      </md-field>
      <md-field class="status-filter">
        <label for="status=">Status</label>
        <md-select
          id="status="
          v-model="statusFilter"
          name="status="
          multiple
        >
          <md-option value="active">
            Active
          </md-option>
          <md-option value="inactive">
            Inactive
          </md-option>
        </md-select>
      </md-field>
      <md-field class="group-filter">
        <label for="group=">Group</label>
        <md-select
          id="group="
          v-model="groupFilter"
          name="group="
          multiple
        >
          <md-option value="customers">
            Customer
          </md-option>
          <md-option value="translators">
            Translators
          </md-option>
        </md-select>
      </md-field>
      <div class="control">
        <md-button
          class="md-primary"
          @click="inquireCreate"
        >
          Create user
        </md-button>
      </div>
    </div>

    <div
      v-if="users && projects"
      class="user-list"
    >
      <md-table class="table">
        <md-table-row>
          <md-table-head />
          <md-table-head>Surname</md-table-head>
          <md-table-head>Forename</md-table-head>
          <md-table-head>Company</md-table-head>
          <md-table-head>Status</md-table-head>
          <md-table-head>Groups</md-table-head>
          <md-table-head>Email</md-table-head>
        </md-table-row>
        <md-table-row
          v-for="user in filteredUser"
          :key="user._id"
        >
          <md-table-cell class="projects-cell">
            <span class="project-badge">{{ user.projects.length }}</span>
            <div class="projects">
              <h4>Projects</h4>
              <ul>
                <li
                  v-for="project in user.projects"
                  :key="project"
                >
                  <fbt-project-small :project="getProject(project)" />
                </li>
              </ul>
            </div>
          </md-table-cell>
          <md-table-cell>{{ user.surname }}</md-table-cell>
          <md-table-cell>{{ user.forename }}</md-table-cell>
          <md-table-cell>{{ user.company }}</md-table-cell>
          <md-table-cell
            class="status"
            :class="{ 'active': user.active }"
          >
            <span v-if="user.active">Active</span>
            <span v-else>Inactive</span>
          </md-table-cell>
          <md-table-cell>{{ user.groups.join(', ') }}</md-table-cell>
          <md-table-cell>{{ user.email }}</md-table-cell>
          <md-table-cell>
            <md-button
              class="md-icon-button md-dense"
              @click="inquireEdit(user)"
            >
              <md-icon>edit</md-icon>
            </md-button>
            <md-button
              class="md-icon-button md-dense"
              @click="inquireRemove(user)"
            >
              <md-icon>delete</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <div
      v-if="loading"
      class="spinner-container"
    >
      <div>
        <spinner />
      </div>
    </div>
    <md-dialog
      :md-active.sync="editUserDialog"
      class="edit-user-dialog big-dialog"
    >
      <div
        v-if="editUser && projects"
        class="edit-user-container"
      >
        <md-dialog-title>Edit user</md-dialog-title>
        <edit-user
          :user="editUser"
          :groups="groups"
          :projects="projects"
          @submit="save"
          @user-changed="changedUserData => user = changedUserData"
        >
          <md-dialog-actions>
            <md-button
              :disabled="saving"
              @click="cancelEdit"
            >
              Cancel
            </md-button>
            <spinner-button
              spin-replace
              type="submit"
              class="md-primary md-raised save-button"
              :disabled="saving"
              :spin="saving"
            >
              Save
            </spinner-button>
          </md-dialog-actions>
        </edit-user>
      </div>
    </md-dialog>
    <md-dialog
      :md-active.sync="createUserDialog"
      class="create-user-dialog big-dialog"
    >
      <div
        v-if="projects"
        class="create-user-container"
      >
        <md-dialog-title>Create user</md-dialog-title>
        <edit-user
          ref="editUser"
          :user.sync="newUser"
          :groups="groups"
          :projects="projects"
          @submit="create"
        >
          <md-dialog-actions class="dialog-actions">
            <md-button
              :disabled="creating"
              @click="cancelCreate"
            >
              Cancel
            </md-button>
            <spinner-button
              spin-replace
              type="submit"
              class="md-primary md-raised create-button"
              :disabled="creating"
              :spin="creating"
            >
              Create
            </spinner-button>
          </md-dialog-actions>
        </edit-user>
      </div>
    </md-dialog>
    <md-dialog :md-active.sync="removeUserDialog">
      <md-dialog-title>Delete User</md-dialog-title>
      <md-dialog-content>Do you want to delete the user?</md-dialog-content>
      <md-dialog-actions>
        <md-button
          :disabled="removing"
          class="md-primary"
          @click="abortRemove"
        >
          Abort
        </md-button>
        <spinner-button
          spin-replace
          class="md-primary"
          :disabled="removing"
          :spin="removing"
          @click="remove"
        >
          Delete
        </spinner-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import SpinnerButton from '../components/spinner-button';
import EditUser from '../components/edit-user';
import FbtProjectSmall from '../components/fbt-project-small';
import Spinner from '../components/spinner';

/**
 * Creates a function to check if a object contains a substring in the specified props
 **/
const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 50,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['forename', 'surname', 'company', 'email'],
};

export default {
  name: 'UserList',
  components: {
    SpinnerButton,
    EditUser,
    FbtProjectSmall,
    Spinner,
  },
  data() {
    return {
      loading: false,
      projects: null,
      users: [],
      editUser: null,
      removeUser: null,
      saving: false,
      creating: false,
      removing: false,
      defaultUser: {
        active: true,
        company: '',
        email: '',
        forename: '',
        surname: '',
        groups: [],
        projects: [],
      },
      newUser: null,
      search: '',
      statusFilter: ['active', 'inactive'],
      groupFilter: ['customers', 'translators'],
      editUserDialog: false,
      createUserDialog: false,
      removeUserDialog: false,
    };
  },
  computed: {
    filteredUser() {
      if (!this.users.length) return;

      const users = this.users
        .filter(user => {
          return (user.active && this.statusFilter.includes('active'))
            || (!user.active && this.statusFilter.includes('inactive'));
        })
        .filter(user => user.groups.some(group => this.groupFilter.includes(group.toLowerCase())));

      if (this.search === '') return users.sort(this.alphabetic);

      const fuzzy = new Fuse(users, fuseOptions).search(this.search);
      return fuzzy.map(user => user.item).sort(this.alphabetic);
    },
  },
  async mounted() {
    this.newUser = Object.assign({}, this.defaultUser);
    this.loading = true;
    [this.users, this.groups, this.projects] = await Promise.all([
      this.$http.get('/users').then(response => response.data),
      this.$http.get('/groups').then(response => response.data),
      this.$http.get('/projects?simple=false').then(response => response.data),
    ]).catch(error => {
      this.$notify({
        title: 'Failed to fetch users, groups or projects!',
        text: error.response && error.response.data.message || 'Unknown Error',
        type: 'error',
        duration: 8000,
      });
    });
    this.loading = false;
  },
  methods: {
    alphabetic(a, b) {
      const one = a.surname.toLowerCase();
      const two = b.surname.toLowerCase();
      return one === two ? 0 : one < two ? -1 : 1;
    },
    inquireCreate() {
      this.createUserDialog = true;
    },
    inquireRemove(user) {
      this.removeUserDialog = true;
      this.removeUser = user;
    },
    getProject(id) {
      return this.projects.find(project => project.id === id);
    },
    cancelEdit() {
      this.editUserDialog = false;
      this.editUser = null;
    },
    remove() {
      this.removing = true;
      this.$http.delete(`/users/${this.removeUser._id}`).then(response => {
        this.users.splice(this.users.findIndex(
          user => user._id === response.data._id,
        ), 1);
        this.removeUser = null;
        this.removeUserDialog = false;
      }).catch(error => {
        this.$notify({
          title: 'Failed to delete user!',
          text: error.response && error.response.data.message || 'Unknown Error',
          type: 'error',
          duration: 8000,
        });
      }).then(() => this.removing = false);
    },
    abortRemove() {
      this.removeUserDialog = false;
      this.removeUser = null;
    },
    create() {
      this.creating = true;
      this.$http.post('/users', this.newUser).then(response => {
        this.users.push(response.data);
        this.$refs.editUser.reset();
        this.createUserDialog = false;
      }).catch(error => {
        this.$notify({
          title: 'Failed to create user!',
          text: error.response && error.response.data.message || 'Unknown Error',
          type: 'error',
          duration: 8000,
        });
      }).then(() => this.creating = false);
    },
    cancelCreate() {
      this.newUser = Object.assign({}, this.defaultUser);
      this.createUserDialog = false;
    },
    save() {
      this.saving = true;
      const toApi = Object.assign({}, this.editUser);
      if (this.editUser.password) delete toApi._pwd;
      this.$http.put(`/users/${this.editUser._id}`, toApi).then(response => {
        const user = this.users.findIndex(u => u._id === response.data._id);
        this.users[user] = Object.assign({}, response.data);
        this.editUser = null;
        this.editUserDialog = false;
      }).catch(error => {
        this.$notify({
          title: 'Failed to update user!',
          text: error.response && error.response.data.message || 'Unknown Error',
          type: 'error',
          duration: 8000,
        });
      }).then(() => this.saving = false);
    },
    inquireEdit(user) {
      this.editUser = Object.assign({}, user);
      this.editUserDialog = true;
    },
  },
};
</script>

<style lang="scss">
.warning {
  color: #541c0b;
  padding: 1rem;
  margin: 0.5rem 0;
  background-color: #ffccbc;
  font-size: 17px;
  border-radius: 6px;
  line-height: 1.5;
}

.create-user-container, .edit-user-container {
  min-width: 500px;
  max-width: auto;
  padding: 30px 10px 10px 10px;
  overflow-y: auto;
}

#app .admin-user-list {
  .md-table {
    overflow-x: initial;

    table {
      overflow: initial;
    }
  }

  .md-table-row {
    position: relative;
  }
  .md-table-cell-container {
    display: flex !important;
  }

  .project-badge {
    background-color: #e2e2e2;
    border-radius: 100%;
    width: 1.5em;
    height: 1.5em;
    display: block;
    text-align: center;
  }

  .projects-cell:hover {
    .projects {
      display: block;
    }

    .md-table-cell {
      background-color: transparent;
    }
    li {
      margin-bottom: 5px;
    }
  }

  .avatar-cell .md-table-head-text,
  .avatar-cell .md-table-cell-container {
    padding-right:0px;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  .md-content {
    background: #fafafa;
    .md-icon {
      color: black;
    }
  }
}
.create-button span, .save-button span{
  color: #fff;
}

</style>

<style scoped lang="scss">
.user-list {
  box-shadow: 0 2px 3px #ababab;
  background-color: #fafafa;
  border-radius: 3px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.control {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  button {
    margin-bottom: 0;
  }
}

.md-table {
  display: flex;
  flex: 1;
}

.projects {
  box-sizing: border-box;
  position: absolute;
  left: 15px;
  top: 0;
  width: 250px;
  margin-top: 60px;
  // margin-top: 500%;
  z-index: 999;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 1px 8px rgba(0,0,0,.2), 0 3px 4px rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12);
  border-radius: 3px;
  display: none;

  ul {
    margin: 5px 0px 5px 0px;
  }

  h4{
    margin: 10px 0px 15px 0px;
  }
}

.el-avatar {
  padding: 0px;
}

.projects::after{
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  z-index: 999;
  top: -9px;
  left: 19px;
  margin-left: -10px;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
}

.projects::before{
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  top: -10px;
  left: 19px;
  margin-left: -10px;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #E6E6E6 transparent;
}

.status {
  text-transform: uppercase;
  color: #9e9e9e;

  &.active {
    color: #8bc34a;
  }
}

.filter {
  background-color: #fafafa;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding: 1em 1.5em 2em;
  border-bottom: 1px dotted #e6e6e6;
  background-color: #fff;

  .search,
  .status-filter,
  .group-filter {
    flex: 1 1 auto;
    margin: 0 .5em;
  }
  .md-icon {
    position: static;
  }
  .md-icon::after {
    background: transparent;
  }
}
</style>

import moment from 'moment';

export default {
  name: 'el-date',
  props: ['date'],
  data() {
    return { display: '' };
  },
  render(createElement) {
    return createElement('div', {
      domProps: {
        className: 'el-date',
      },
    }, this.display);
  },
  mounted() {
    this.display = moment(this.date).fromNow();
    setInterval(() => {
      this.display = moment(this.date).fromNow();
    }, 1000 * 30);
  },
};

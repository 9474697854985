<template>
  <div class="centered-container">
    <div class="fb-box small md-whiteframe md-whiteframe-1dp">
      <div class="header">
        <span><md-icon>priority_high</md-icon>Not found</span>
      </div>
      <div class="content centered-container">
        <p>This project has no deliverables (yet)</p>
        <md-button
          class="md-primary submit"
          type="button"
          @click="back('/')"
        >
          <span>Back to Projects List</span>
        </md-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotDeliverables',
  methods: {
    back(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 20px;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}
</style>

<template>
  <md-button
    class="spinner-button"
    :disabled="disabled"
    :class="{ 'spin-replace': spinReplace }"
    v-on="$listeners"
  >
    <spinner v-if="spin" />
    <span
      v-if="!spin && $slots.icon"
      class="icon"
    ><slot name="icon" /></span>
    <span
      v-if="!(spin && spinReplace)"
      class="content"
    ><slot /></span>
  </md-button>
</template>

<script>
import Spinner from './spinner';

export default {
  name: 'SpinnerButton',
  components: {
    Spinner,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    spin: {
      type: Boolean,
      default: false,
    },
    spinReplace: {
      type: Boolean,
      default: false,
    },
    spinnerClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.spinner-button {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
  }

  &.spin-replace {
    justify-content: center;
    align-items: center;
  }
}

.icon {
  margin-right: 5px;
}

#app .spinner {
  width: 25px;
  height: 25px;
  margin: 0px;
  position: static;
  margin-right: 5px;
}
</style>

<template>
  <div>
    <div class="upload-button has-ripple md-button">
      <file-upload
        v-show="!uploadingImage"
        ref="upload"
        v-model="files"
        name="file"
        :post-action="uploadUrl"
        :headers="headers"
        @input-file="uploadFile"
        @input-filter="fixWithCredentials"
      >
        <span>Upload file</span>
      </file-upload>
    </div>
    <div
      v-show="uploadingImage"
      class="image-upload-spinner"
    >
      <spinner />
      <span>Uploading file</span>
    </div>
  </div>
</template>

<script>

import FileUpload from 'vue-upload-component';
import { mapState } from 'vuex';
import Spinner from '../components/spinner';

export default {
  name: 'FileUploader',
  components: {
    FileUpload,
    Spinner,
  },
  props: {
    editor: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  data() {
    return {
      uploadingImage: false,
      headers: this.$http.defaults.headers.common,
      files: [],
      hasEditor: false,
    };
  },
  computed: {
    ...mapState(['activeProject']),
    uploadUrl() {
      return `${this.$http.defaults.baseURL}projects/${this.activeProject.id}/attachments`;
    },
  },
  watch: {
    editor(editor) {
      if (!editor || this.hasEditor) return;
      const self = this;
      const inlineAttachmentConfig = {
        uploadUrl: this.uploadUrl,
        progressText: '',
        onFileReceived() {
          self.uploadingImage = true;
        },
        onFileUploadResponse(xhr) {
          self.uploadingImage = false;
          self.$emit('uploaded', {
            response: JSON.parse(xhr.response),
          });
        },
        beforeFileUpload(xhr) {
          xhr.withCredentials = true;
        },
      };

      window.inlineAttachment.editors.codemirror4.attach(
        editor.codemirror,
        inlineAttachmentConfig,
      );
      this.hasEditor = true;
    },
  },
  methods: {
    fixWithCredentials(file) {
      // components calls this with garbage data
      if (!file || !file.xhr) return;

      if (file.xhr.withCredentials) return;
      // send with auth cookie!
      file.xhr.withCredentials = true;
    },
    uploadFile(file) {
      this.$refs.upload.active = true;
      this.uploadingImage = true;
      if (!file) return;
      if (file.error) this.handleError(file);
      if (file.success) this.fileUploaded(file);
    },
    handleError(file) {
      // we debounce showing error message because this function
      // is called twice -> problem in file-upload plugin
      clearTimeout(this.errorMessageTimeout);
      this.errorMessageTimeout = setTimeout(() => {
        this.uploadingImage = false;
        this.$emit('error', file.response.error);
      }, 100);
    },
    fileUploaded(file) {
      this.uploadingImage = false;
      this.$emit('uploaded', file);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~colors';
.upload-button {
  display: flex;
  align-items: center;
  padding: 0 8px;
  cursor: pointer;
  &:hover {
    background-color: rgba(153, 153, 153, 0.2);
  }
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

label {
  cursor: inherit;
  span {
    color: $primary-color;
    text-transform: uppercase;
    line-height: 36px;
  }
}

.file-uploads {
  overflow: visible;
}

.image-upload-spinner {
  display: flex;
  padding: 4px 12px;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 6px;
    color: rgba(0, 0, 0, 0.55);
  }
}
.spinner {
  position: static;
  left: 0;
  top: 5px;
  margin-right: 5px;
  margin-top: 0px;
  width: 20px;
  height: 20px;
}
</style>

<template>
  <div class="page page-margin create-issue">
    <edit-issue
      :issue="issue"
      :edit="true"
      @submit="save"
    >
      <div class="buttons">
        <md-button
          class="md-raised cancel-button"
          :disabled="saving"
          @click="cancel"
        >
          <span>Cancel</span>
        </md-button>
        <spinner-button
          spin-replace
          class="save-button md-raised md-primary"
          type="submit"
          :spin="saving"
          :disabled="saving"
        >
          <span>Save</span>
        </spinner-button>
      </div>
    </edit-issue>
  </div>
</template>

<script>
import EditIssue from '../components/edit-issue';
import { mapState } from 'vuex';
import SpinnerButton from '../components/spinner-button';

export default {
  name: 'CreateIssue',
  components: {
    EditIssue,
    SpinnerButton,
  },
  data() {
    return {
      saving: false,
      issue: {
        title: '',
        description: '',
        deliverableIds: [parseInt(this.$route.params.deliverableId, 10)],
      },
    };
  },
  computed: {
    ...mapState(['activeProject']),
  },
  methods: {
    goToIssues() {
      this.$router.push({
        name: 'deliverable',
        params: {
          projectId: this.$route.params.projectId,
          deliverableId: this.$route.params.deliverableId,
        },
      });
    },
    save() {
      this.saving = true;
      this.$http.post(`/projects/${this.activeProject.id}/issues`, this.issue).then(() => {
        this.$notify({
          text: 'Issue created!',
          type: 'success',
          duration: 8000,
        });
        this.goToIssues();
      }).catch(error => {
        this.$notify({
          title: 'Failed to create issue!',
          text: error.response && error.response.data.message || 'Unknown Error',
          type: 'error',
          duration: 8000,
        });
      }).then(() => {
        this.saving = false;
      });
    },
    cancel() {
      this.goToIssues();
    },
  },
};

</script>

<style lang="scss">
#app .create-issue {
  .title-input input {
    line-height: 20px;
    font-weight: bold;
    font-size: 2em;
    border-bottom: 1px solid #ccc;
    height: 50px;
    border:none;
  }

  .title-input label {
    font-size: 1.6em;
    top: 38px;
  }

  .cancel-button {
    background-color: #e6e6e6;

    &:hover {
      background-color: rgba(120, 120, 120, 0.3);
    }
  }
}

#app .create-issue .title-input.md-input-focused label,
#app .create-issue .title-input.md-has-value label {
  font-size: 12px;
  top: 4px;
}
</style>

<style lang="scss" scoped>
.control{
  display: flex;

  h1 {
    flex: 1;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;

  button:last-of-type {
    margin-right: 0px;
  }

  button.md-button{
    box-shadow: 0 0 transparent;
  }
  .save-button span{
    color: #fff;
  }
}
</style>

<template>
  <div class="avatar">
    <div>{{ projectFirstChar }}</div>
  </div>
</template>

<script>
export default {
  name: 'ProjectAvatar',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    projectFirstChar() {
      const name = this.project.description || this.project.name;
      return name.slice(0, 1).toUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~colors';

.avatar {
  margin-right: 15px;
  height: 40px;
  width: 40px;
  max-width: 40px;
  min-width: 40px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 100%;

  img {
    width: 100%;
  }

  div {
    max-width: 40px;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
    font-weight: bold;
    background-color: $primary-color;
    width: 100%;
    height: 100%;
  }
}
</style>

<template>
  <div
    v-if="project"
    class="project"
  >
    <project-avatar :project="project" />
    <span>{{ project.name }}</span>
  </div>
</template>

<script>
import ProjectAvatar from './project-avatar';

export default {
  name: 'FbtProjectSmall',
  components: {
    ProjectAvatar,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~colors';

.project {
  display: flex;
  align-items: center;
}
</style>

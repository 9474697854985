import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const logout = () => {
  const logoutUrl = new URL('/account/logout', window.API_URL || process.env.API_URL);
  window.location.assign(logoutUrl.toString());
};

const store = new Vuex.Store({
  state: {
    user: null,
    activeProject: null,
    issueContext: [],
  },
  mutations: {
    setIssueContext(state, context) {
      state.issueContext = context;
    },
    setUser(state, user) {
      state.user = user;
    },
    setActiveProject(state, project) {
      state.activeProject = project;
    },
    async logout() {
      // remove customer form local or hydra user from session
      window.sessionStorage.removeItem('feedback_user');
      window.localStorage.removeItem('feedback_user');

      logout();
    },
  },
  getters: {
    isUser: state => {
      return state.user && !state.user.superAdmin;
    },
    isAdmin: state => {
      return state.user && state.user.superAdmin;
    },
  },
});

export default store;

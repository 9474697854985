import Vue from 'vue';
import Router from 'vue-router';

import ProjectList from './pages/project-list.vue';
import ProjectDetails from './pages/project-details';
import IssueList from './pages/issue-list';
import IssueDetails from './pages/issue-details';
import UserList from './pages/user-list';
import UserLogin from './pages/user-login';
import NotFound from './pages/not-found';
import CreateIssue from './pages/create-issue';

import store from './store';
import Axios from 'axios';

Vue.use(Router);

const routes = [{
  path: '/login/',
  name: 'login',
  component: UserLogin,
  beforeEnter(to, from, next) {
    if (store.getters.isUser) return next('/');
    return next();
  },
}, {
  path: '/',
  name: 'projects',
  component: ProjectList,
}, {
  path: '/404',
  name: 'notFound',
  component: NotFound,
}, {
  path: '/projects/:projectId/',
  component: ProjectDetails,
  name: 'project.details',
  children: [{
    path: 'issues/',
    name: 'issues',
    component: IssueList,
    children: [{
      name: 'deliverable',
      path: ':deliverableId/',
    }],
  }, {
    path: 'issues/:deliverableId/:issueId(\\d+)',
    name: 'issue.details',
    component: IssueDetails,
  }, {
    path: 'issues/:deliverableId/create',
    name: 'issue.create',
    component: CreateIssue,
  }],
}, {
  path: '/admin/users',
  name: 'admin.users',
  component: UserList,
  beforeEnter(to, from, next) {
    if (store.getters.isAdmin) return next();
    return next(from.path);
  },
}, {
  path: '*',
  component: NotFound,
}];

const router = new Router({ routes });

const authUrl = new URL('/account', window.API_URL || process.env.API_URL);
const userPromise = Axios.get(authUrl, { withCredentials: true })
  .then(r => {
    store.commit('setUser', r.data);
    return r.data;
  }).catch(() => null);

router.beforeEach(async (to, from, next) => {
  const user = await userPromise;
  if (to.name === 'login' || to.name === 'admin') return next();

  if (!user) return next('/login');
  return next();
});

export default router;

<template>
  <div class="edit-user">
    <form
      ref="form"
      @submit.prevent="$emit('submit')"
    >
      <div class="settings">
        <div class="basic-inputs">
          <md-field>
            <label>Surname</label>
            <md-input
              v-model="userData.surname"
              required
            />
          </md-field>
          <md-field>
            <label>Forename</label>
            <md-input
              v-model="userData.forename"
              required
            />
          </md-field>
          <md-field>
            <label>Company</label>
            <md-input
              v-model="userData.company"
              required
            />
          </md-field>
          <md-field>
            <label for="groups=">Groups</label>
            <md-select
              id="groups="
              v-model="userData.groups"
              name="groups="
              multiple
            >
              <md-option
                v-for="group in groups"
                :key="group"
                :value="group"
              >
                {{ group }}
              </md-option>
            </md-select>
          </md-field>
          <md-checkbox v-model="userData.active">
            Active
          </md-checkbox>
          <md-field>
            <label>Email</label>
            <md-input
              v-model="userData.email"
              required
            />
          </md-field>
          <md-field md-has-password>
            <label>Password</label>
            <md-input
              v-model="userData.password"
              type="password"
            />
          </md-field>
        </div>
        <div class="available-projects">
          <div class="label">
            Available Projects
          </div>
          <md-field class="search">
            <md-icon>search</md-icon>
            <label>Filter projects</label>
            <md-input v-model="search" />
          </md-field>
          <projects-select
            :user.sync="user"
            :projects="filteredProjects"
            @user-changed="changedUserData => userData = changedUserData"
          />
        </div>
      </div>
      <slot />
    </form>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import ProjectsSelect from './projects-select';

const fuseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 50,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  keys: ['name'],
};

export default {
  name: 'EditUser',
  components: {
    ProjectsSelect,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    projects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    filteredProjects() {
      const projects = this.projects.slice(0);

      if (!this.search) {
        return projects.sort((a, b) => {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
        });
      }

      const fuse = new Fuse(this.projects, fuseOptions).search(this.search);
      return fuse.map(project => project.item);
    },
    userData: {
      get() {
        return this.user;
      },
      set(changedUserData) {
        this.$emit('user-changed', changedUserData);
      },
    },
  },
  methods: {
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.edit-user {
  padding: 0px 24px 0;
  min-width: 800px;
}
.md-dialog-actions {
  padding: 0px;
}
.search {
  .md-icon {
    position: static;
  }
  .md-icon::after {
    background: transparent;
  }
}

form {
  display: flex;
  flex-direction: column;
}

.settings {
  display: flex;
  flex: 1 1 auto;
  margin-bottom: 15px;
}

.basic-inputs {
  min-width: 240px;
  margin-right: 20px;
  flex: 0 0 20em;
}

.available-projects, .basic-inputs {
  display: flex;
  flex-direction: column;
}

.available-projects {
  margin-left: 20px;
  margin-top: 2px;
  flex: 1 1 auto;

  .projects-select {
    max-height: 410px;
    overflow: auto;
    margin-top: 10px;
  }

  .label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
  }
}
</style>

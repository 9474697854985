<template>
  <div id="app">
    <notifications
      class="notifications"
      position="bottom center"
    />
    <header class="md-whiteframe md-whiteframe-1dp">
      <md-toolbar class="top-navbar">
        <div class="left-items">
          <router-link
            class="logo"
            to="/"
          >
            <img
              :src="require('./assets/images/fit_logo.svg').default"
              alt=""
            >
          </router-link>
          <el-breadcrumbs />
        </div>
        <div class="right-items">
          <router-link
            v-if="!user"
            v-show="$route.name === 'admin'"
            to="/login"
          >
            <md-button>User Login</md-button>
          </router-link>
          <el-menu />
        </div>
      </md-toolbar>
    </header>
    <main>
      <router-view class="pagewidth" />
    </main>
    <footer>
      <div class="pagewidth">
        <div class="code">
          &copy; Copyright 2018
        </div>
        <div class="footer-hydra">
          Look, a <img
            class="hydra-logo"
            :src="require('./assets/images/hydra_logo.svg').default"
            alt=""
          > productivity tool.
        </div>
        <div class="">
          <a
            class="link"
            href="mailto:kontakt@hydra-newmedia.com"
          >
            <i class="material-icons">chevron_right</i> Contact us
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ElBreadcrumbs from './components/el-breadcrumbs';
import ElMenu from './components/el-menu';

export default {
  name: 'App',
  components: {
    ElBreadcrumbs,
    ElMenu,
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isAdmin', 'isUser']),
  },
};
</script>

<style lang="scss">
@import "basic-style";
@import "vue-material";

body, html {
  background-color: #eeeeee !important;
  background-image: url('./assets/images/pattern.svg');
  background-size: 20px 20px;
}

// we go full bootstrap here, to punch throu
// #app .md-input-container.md-input-focused label {
//   color: gray;
// }
//
// .md-select-content .md-menu-item.md-selected,
// .md-select-content .md-menu-item.md-checked {
//   background-color: rgb(244, 244, 244);
// }

#app .logo img {
  height: 28px;
  margin-left: 10px;
}

#app {
  .notifications {
    bottom: 10px !important;
    width: 350px !important;
    left: calc(50% - 175px) !important;
  }

  .notification {
    background-color: #fafafa;
    font-family: inherit;
    border-left: none;
    padding: 12px 16px;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 0 1px 5px rgba(0,0,0,.2), 0 2px 2px rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);

    .notification-title {
      font-weight: 500;
      font-size: 16px;
      color: #000;
      margin-bottom: 5px;
    }

    .notification-content {
      font-size: 14px;
      color: #000;
    }

    &.success {
      border-left: 5px solid $success;
    }

    &.warning {
      border-left: 5px solid $warn;
    }

    &.error {
      border-left: 5px solid $error;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~colors";

// we use the powerful #app selector to overwrite styles in e.g. vue-material

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app header {
  height: 64px;
  box-shadow: 0 0 transparent;
  flex: 0 0 64px;
  position: relative;
  z-index: 30;

  &::after {
    position: absolute;
    bottom: -6px;
    left: 0;
    content: "";
    width: 100%;
    height: 7px;
    background-size: 11px 7px;
    background-image: url('./assets/images/zacken.svg');
    background-repeat: repeat-x;
  }

  .top-navbar {
    display: flex;
    justify-content: space-between;
    background-color: black;
    z-index: 30;
    position: relative;
    box-shadow: none;
    height: 64px;
  }

  a {
    color: #ffffff;
  }

  .right-items, .left-items {
    display: flex;
    align-items: center;
  }
}

main {
  display: flex;
  flex: 1 1 auto;
  min-height: calc(100vh - 110px);
  justify-content: center;
}

footer .hydra-logo {
  margin: 0 8px;
  height: 19px;
}

footer {
  background-color: black;
  z-index: 120;
  position: relative;
  height: 36px;
  color: white;
  font-weight: 400;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin-top: 10px;
  flex: 0 0 36px;

  .pagewidth {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
  }

  a {
    display: inline-flex;
    align-items: center;
  }

  a > i.material-icons {
    font-size: 24px;
    line-height: 0;
  }

  &::after {
    position: absolute;
    top: -6px;
    left: 0;
    content: "";
    width: 100%;
    height: 7px;
    background-size: 11px 7px;
    background-image: url('./assets/images/zacken_up.svg');
    background-repeat: repeat-x;
    z-index: 100;
  }

  a.link {
    color: white;
  }

  a.link:hover {
    color: rgb(215, 215, 215);
  }

  .md-icon {
    margin-right: 8px;
  }

  .footer-hydra {
    white-space: nowrap;
  }
}
</style>

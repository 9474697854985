<template>
  <div class="breadcrumbs">
    <div
      v-if="isDeliverableSubPage"
      class="item"
    >
      <md-icon>chevron_right</md-icon>
      <router-link
        :to="{
          name: 'deliverable',
          params: {
            project: $route.params.projectId,
            deliverableId: $route.params.deliverableId,
          },
          query: $route.query,
        }"
      >
        <md-button>
          <span>{{ activeProject.name }}</span>
          <span>-</span>
          <span>{{ getDeliverableName($route.params.deliverableId) }}</span>
        </md-button>
      </router-link>
    </div>
    <div
      v-if="isCreateIssuePage"
      class="item"
    >
      <md-icon>chevron_right</md-icon>
      <md-button>
        <span>Create new Issue</span>
      </md-button>
    </div>
    <div
      v-if="isIssuePage"
      class="item"
    >
      <md-icon>chevron_right</md-icon>
      <router-link
        :to="{
          name: 'issue.details',
          params: {
            project: $route.params.projectId,
            deliverableId: $route.params.deliverableId,
            issue: $route.params.issueId,
          }
        }"
      >
        <md-button>
          <span>#{{ $route.params.issueId }}</span>
        </md-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ElBreadcrumbs',
  data() {
    return {};
  },
  computed: {
    isDeliverableSubPage() {
      return this.routeHas('projectId', 'deliverableId') && this.activeProject;
    },
    isCreateIssuePage() {
      return this.routeHas('projectId', 'deliverableId') &&
        this.activeProject &&
        this.$route.name === 'issue.create';
    },
    isIssuePage() {
      return this.routeHas('projectId', 'deliverableId', 'issueId') && this.activeProject;
    },
    ...mapState(['activeProject']),
  },
  methods: {
    routeHas(...params) {
      return !params.some(p => this.$route.params[p] === undefined);
    },
    getDeliverableName(id) {
      const deliverable = this.activeProject.deliverables.find(deliverable => {
        return deliverable.iid === parseInt(id, 10);
      });
      return deliverable && deliverable.title;
    },
  },
};
</script>

<style scoped>
.breadcrumbs {
  padding: 0px 30px;
  display: flex;
}
span {
  color: #fff;
}
.item {
  display: flex;
  align-items: center;
  color: #fff;
}

.md-button {
  min-width: 0;
  color: #fff;
}

#app .md-icon-font {
  color: #fff;
}
</style>

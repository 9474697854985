<template>
  <nav
    v-if="user"
    class="el-menu"
  >
    <we-button
      class="menu-toggle"
      @click="isMenuOpen = !isMenuOpen"
    >
      <md-icon>more_vert</md-icon>
    </we-button>
    <transition name="pop">
      <div
        v-show="isMenuOpen"
        class="menu-items md-whiteframe md-whiteframe-2dp"
      >
        <router-link
          class="user-list-link"
          to="/"
        >
          <we-button @click="isMenuOpen = false">
            <span>Project List</span>
          </we-button>
        </router-link>
        <router-link
          v-if="isAdmin"
          class="user-list-link"
          to="/admin/users"
        >
          <we-button @click="isMenuOpen = false">
            <span>User List</span>
          </we-button>
        </router-link>
        <div class="divider" />
        <el-avatar :user="user" />
        <we-button @click="logout">
          <i class="material-icons">exit_to_app</i>
          <span>Logout</span>
        </we-button>
      </div>
    </transition>
  </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { WeButton } from '@wombats/wombat-elements';
import ElAvatar from './el-avatar';

export default {
  name: 'ElMenu',
  components: {
    WeButton,
    ElAvatar,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isAdmin', 'isUser']),
  },
  mounted() {
    document.addEventListener('click', this.blurred);
  },
  destroyed() {
    document.removeEventListener('click', this.blurred);
  },
  methods: {
    blurred(e) {
      if (this.$el.contains(e.target)) return;
      this.isMenuOpen = false;
    },
    logout() {
      this.isMenuOpen = false;
      this.$store.commit('logout');
    },
  },
};
</script>

<style lang="scss">
.el-menu.el-menu {
  .we-button {
    margin: 0px;
    width: 100%;
    border-radius: 0px;
  }

  .we-button:not(.menu-toggle) i {
    font-size: 19px;
  }

  .we-button, .el-avatar {
    justify-content: center;
    padding: 12px;
  }

  .el-avatar .md-avatar  {
    margin-left: 0px;
    color: #000;
  }
  .we-button .md-icon {
    color: #fff;
  }
}

</style>

<style lang="scss" scoped>
@import '~colors';

.el-menu {
  position: relative;
}

.menu-toggle.menu-toggle {
  color: #fff;
  padding: 8px;
  border-radius: 100%;
}

.menu-items {
  position: absolute;
  right: 0px;
  background: #fff;
  z-index: 99;
  color: #000;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  min-width: 200px;
}

.menu-items a, .menu-items a:hover {
  color: #000;
}

.menu-items a.router-link-exact-active {
  color: $primary-color;
}

.divider {
  width: 100%;
  height: 1px;
  background: #ccc;
}

.pop-enter-active, .pop-leave-active {
  transition: opacity .3s, transform .3s;

}
.pop-enter, .pop-leave-to {
  opacity: 0;
  transform: translateY(4px);
}
</style>

<template>
  <div class="page page-margin">
    <h1 v-if="projects">
      Your Projects
    </h1>
    <div
      v-if="!loading && projects"
      class="content"
    >
      <ul class="projects">
        <li
          v-for="project in projects"
          :key="project.id"
          class="project"
        >
          <div
            class="information has-ripple"
            @click="goToIssues(project)"
          >
            <project-avatar :project="project" />
            <h3>{{ project.name }}</h3>
            <div class="created">
              Created {{ getTimeFrom(project.created_at) }}
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-if="loading"
      class="spinner-container"
    >
      <spinner />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ProjectAvatar from '../components/project-avatar';
import Spinner from '../components/spinner';

export default {
  name: 'ProjectList',
  components: {
    ProjectAvatar,
    Spinner,
  },
  data() {
    return {
      projects: null,
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    this.$http.get('/projects?simple=true')
      .then(response => this.projects = response.data)
      .catch(e => {
        console.error(e);
        this.$notify({
          text: 'Something went wrong when fetching the projects!',
          type: 'error',
          duration: 8000,
        });
      })
      .then(() => this.loading = false);
  },
  methods: {
    getProjectFirstChar(project) {
      const name = project.description || project.name;
      return name.slice(0, 1).toUpperCase();
    },
    getTimeFrom(start) {
      return moment(start).fromNow();
    },
    goToIssues(project) {
      this.$router.push({
        name: 'project.details',
        params: {
          projectId: project.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~colors';

.projects {
  display: flex;
  flex-direction: column;
}

.project {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  background-color: #fafafa;
  box-shadow: 0 1px 2px #a5a5a5;
  margin-bottom: 1em;
  border-radius: 3px;
  position: relative;

  &:hover {
    background-color: #eeeeee;
  }

  &:active {
    background-color: #e7e7e7;
  }
}

.projects li:first-child {
  border-top: 1px solid transparent;
}

.information, .md-menu {
  padding: 20px;
}

.md-menu {
  position: absolute;
  right: 10px;
  top: 10px;

  button {
    margin: 0px;
  }
}

.information {
  display: flex;
  flex: 1;
  align-items: center;
  position: relative;
  cursor: pointer;

  h3 {
    flex: 1;
  }

  .created {
    flex: 1;
    margin-right: 200px;
    color: #6E6E6E;
  }
}

</style>

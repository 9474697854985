import 'normalize-css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import 'vue-material/dist/vue-material.css';
import VueMaterial from 'vue-material';
import axios from 'axios';
import store from './store';
import Notifications from 'vue-notification';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

import 'material-icons/iconfont/material-icons.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto-mono/400.css';

if (process.env.NODE_ENV !== 'development' && window.location.search !== '?disableSentry') {
  Sentry.init({
    dsn: 'https://5ae78976b46d4f42ba58c6455e9297ed@sentry.io/202062',
    integrations: [new VueIntegration({ Vue })],
    release: require('../package.json').version,
    environment: process.env.NODE_ENV,
  });
}

// enable devtools for dev build
if (process.env.DEPLOY_ENV === 'dev') Vue.config.devtools = true;

Vue.use(Notifications);

Vue.prototype.$http = axios;
Vue.use(VueMaterial);

// default api url
axios.defaults.baseURL = window.API_URL || process.env.API_URL;
axios.defaults.withCredentials = true;

// Add a response interceptor
const pass = response => response;
axios.interceptors.response.use(pass, error => {
  if (error.response.status === 401 && !error.config.noInterceptor) {
    store.commit('logout');
    router.push('/login');
  } else {
    return Promise.reject(error);
  }
});

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
});

<template>
  <div class="centered-container">
    <div class="fb-box small md-whiteframe md-whiteframe-1dp">
      <div class="header">
        <span><md-icon>priority_high</md-icon>Not found</span>
      </div>
      <div class="content centered-container">
        <p>The Ressource you are looking for could not be found!</p>
        <md-button
          v-if="isUser"
          class="md-primary submit"
          type="button"
          @click="back('/')"
        >
          <span>Back to Projects List</span>
        </md-button>
        <md-button
          v-else
          class="md-primary submit"
          type="button"
          @click="back('/admin')"
        >
          <span>Back to Users List</span>
        </md-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NotFound',
  computed: {
    ...mapGetters(['isUser']),
  },
  methods: {
    back(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 20px;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}
</style>

<template>
  <div class="el-avatar">
    <md-avatar>
      <img
        v-if="user.avatar_url"
        :src="user.avatar_url"
        alt=""
      >
      <md-icon v-else>
        person
      </md-icon>
    </md-avatar>
    <span v-if="!noName">
      {{ user.forename ? `${user.forename} ${user.surname}` : user.name }}
    </span>
  </div>
</template>

<script>

export default {
  name: 'ElAvatar',
  props: {
    user: {
      type: Object,
      required: true,
    },
    noName: {
      type: Boolean,
      default: false,
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~colors';

.el-avatar {
  display: flex;
  padding: 4px 12px;
  align-items: center;
}

.md-avatar {
  color: $primary-color;
  background: rgba(255, 255, 255, 0.6);
  margin-right: 8px;
  min-height: 28px;
  min-width: 28px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
}

</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"breadcrumbs"},[(_vm.isDeliverableSubPage)?_c('div',{staticClass:"item"},[_c('md-icon',[_vm._v("chevron_right")]),_vm._v(" "),_c('router-link',{attrs:{"to":{
        name: 'deliverable',
        params: {
          project: _vm.$route.params.projectId,
          deliverableId: _vm.$route.params.deliverableId,
        },
        query: _vm.$route.query,
      }}},[_c('md-button',[_c('span',[_vm._v(_vm._s(_vm.activeProject.name))]),_vm._v(" "),_c('span',[_vm._v("-")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.getDeliverableName(_vm.$route.params.deliverableId)))])])],1)],1):_vm._e(),_vm._v(" "),(_vm.isCreateIssuePage)?_c('div',{staticClass:"item"},[_c('md-icon',[_vm._v("chevron_right")]),_vm._v(" "),_c('md-button',[_c('span',[_vm._v("Create new Issue")])])],1):_vm._e(),_vm._v(" "),(_vm.isIssuePage)?_c('div',{staticClass:"item"},[_c('md-icon',[_vm._v("chevron_right")]),_vm._v(" "),_c('router-link',{attrs:{"to":{
        name: 'issue.details',
        params: {
          project: _vm.$route.params.projectId,
          deliverableId: _vm.$route.params.deliverableId,
          issue: _vm.$route.params.issueId,
        }
      }}},[_c('md-button',[_c('span',[_vm._v("#"+_vm._s(_vm.$route.params.issueId))])])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
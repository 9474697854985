<template>
  <div class="user-login">
    <div class="fb-box small md-whiteframe md-whiteframe-1dp">
      <div
        class="header"
        :class="{ error }"
      >
        <span v-if="!error">Feedback Tool</span>
        <span v-else><md-icon>priority_high</md-icon>Invalid username or password</span>
      </div>
      <div class="content">
        Loading ...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserLogin',
  data() {
    return {
      email: '',
      password: '',
      error: false,
      loading: false,
    };
  },
  mounted() {
    if (this.$store.getters.isUser) return this.$router.push('/');
    if (this.$store.getters.isAdmin) return this.$router.push('/admin');
    this.login();
  },
  methods: {
    async login() {
      this.loading = true;

      const loginUrl = new URL('/account/login', window.API_URL || process.env.API_URL);
      loginUrl.searchParams.set('client_id', 'FIT');
      window.location.replace(loginUrl.toString());
    },
  },
};
</script>
<style scoped lang="scss">
.content {
  padding: 20px;
  background-color: #fafafa;
}

.user-login {
  max-width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
